import React, { Component } from "react";

import { Routes, Route, Link, useParams } from "react-router-dom";


import './App.css';
import './anzuStyle.css';
import Tarjeta from "./component/Tarjeta";
import NavBar from "./component/Navbar";
import Areas from "./component/Areas";
import Home from './pages/Home';
import List from "./pages/List";

/* 
  crear link wap https://crear.wa.link/ 
  generar QR https://www.qrcode.es/es/generador-qr-code/

*/



function App() {
  

  return (
    <div className="App">    
    <NavBar/>   
    <Routes >
      <Route path="/" element={<Home/>}/>
      <Route path="list/:id" element={<List/>}/>
      <Route component={<Home/>}/>
    </Routes>
  </div>
  
  );
}

export default App;
