
import React, {useState,useEffect, Component } from "react";
import { Routes, Route,Link, useParams } from "react-router-dom";
import { withRouter } from "react-router";  
import axios from "axios";
import *  as App_constantes from '../cons/App_parametros';


import Tarjeta from "../component/Tarjeta";
const url = App_constantes.API_Url + 'Productos/';



function List() {
  let params = useParams();

  let contenido;

  const [list, setList] = useState([]);
  useEffect(() => {
    axios({
      url: url ,
    })
      .then((response) => {
        setList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);

  const [callList, setCalllist] = useState([]);
  useEffect(() => {
    axios({
      url: url + params.id ,
    })
      .then((response) => {
        setCalllist(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);
  

  

  return (    
      <div className="contenedor">  
        <div className="row d-flex justify-content-center">  
                {list.map((item) => (                  
                      <div className='col-sm-12 col-md-6 col-lg-4'>
                          <Tarjeta 
                            nombre={item.title} 
                              description={item.description}   
                                price={item.price} 
                                isPromotion='1' 
                                image={item.imageUrl}
                                phone={callList.whatsappNumber} 
                                callType={item.callToAction}/>
                      </div>                   
                    ))}   
            
        </div>
        <br/>
        <Link to="/" className="btn  btn-flotante ">Volver</Link>
      </div>
  );
}

export default List;
