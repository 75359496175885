
import React, { useState,useEffect, Component } from "react";
import Areas from "../component/Areas";
import axios from "axios";
import *  as App_constantes from '../cons/App_parametros';
import Tarjeta from "../component/Tarjeta";
  
const url = App_constantes.API_Url + 'Productos/GetProducto';

   
function Home(){

 const[user, setUser] = useState([]);
 const fechData = () =>{
  return axios.get(url)  
  .then((response) => setUser(response.data.data));
  
 }

 useEffect(()=>{
  fechData();
  console.log(user);
 },[]);


  return (    
          <div>
            <div className='Container'>        
                <div className='row d-flex justify-content-center'>                      
                  {user.length >0 && user.map((item, index) => (                      
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                     <Tarjeta
                      nombre={item.nombre}
                      description={item.descripcion}
                     />
                    </div> 
                  ))} 
                </div>
            </div>
        </div>
  )            
}

  
  export default Home;