import React, {Component} from "react";
import { ThemeProvider } from "react-bootstrap";
import { Routes, Route, Link, useParams } from "react-router-dom";

class Areas extends Component{
    render(){
        return(
            <div className="contenedor-area">
                 <img src={this.props.image}/>
                 <div className="imgText"> 
                    <Link to={"list/"+ this.props.area_id} className="btn btn-anzu btn-ancho font-color-primary" >
                        {this.props.titulo}</Link>
                    </div>
                
          </div>
        )
    }
}

export default Areas;