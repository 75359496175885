
import React, {Component} from "react";
import { ThemeProvider } from "react-bootstrap";

const styles = {
    wcard:{
        width:'400px'
    }
}



class Tarjeta extends Component{
    render(){

        let promo;
        let btnLink;
        let whatsappLink;

        if(this.props.price != null)
        {
           promo =  <span class="badge  rounded-pill badge-price">{"U$S " +this.props.price}</span>
           btnLink = "Contactar"
        }else{
            btnLink = "Contactar"
        }

        if(this.props.phone != null)
        {
            var wUrl = "https://api.whatsapp.com/send?phone=";
            var wMsg1 = "&text=Hola%F0%9F%98%80%20me%20gustar%C3%ADa%20recibir%20informaci%C3%B3n%20sobre%20los%20";
            var wLink = wUrl + this.props.phone.replace("+","") + wMsg1 + this.props.nombre;
            whatsappLink = wLink;            
        }

        if(this.props.callType !=null)
        {
            btnLink = this.props.callType.title;
            whatsappLink = this.props.callType.url;
            
        }


        return(
            <div className="d-flex justify-content-center">                
            <div className="card cardAzu " >
                <img className="card-img-top img-fluid" src="https://dqm4sv5xk0oaj.cloudfront.net/products/42004/large/open-uri20220920-7-1tj0h4n.?1663687856" alt="Card image"/>
                <div className="card-body ">
                    <h4 className="card-title card-titulo font-color-primary">{this.props.nombre}
                    {promo}
                    </h4>
                    <div className="">
                    <p className=" font-color-secundary card-description ">{this.props.description}</p>
                    </div>
                </div>
                <div className="card-footer">                    
                    <a href={whatsappLink} target="_blank" className="btn btn-anzu font-color-primary ">{btnLink}</a> 
                </div>
            </div>
            </div>
            );
    }
  }

  export default Tarjeta;