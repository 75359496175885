import React, {Component} from "react";
import logo from "../image/logo.png"

class NavBar extends Component{
    render(){
        return(
            <div>
                <div className='Container'>        
                    <div className='row'>  
                        <div className="col-md-8 navbar-anzu font-color-primary d-flex justify-content-right">
                            <a href="/"><img src={logo} alt="logo"/></a>
                            <p>info@anzu.com</p>
                        </div>                    
                    </div>
                </div>
            </div>
            )
    }
}

export default NavBar;

